import { defineStore } from 'pinia'
import { useSettings } from './settings.store'
import { countryBasedPriceList, priceList } from '@/resources/rimRenovation/priceList'
import {
  PriceList,
  RimRenovationType,
  RimRenovationTypeOption,
  RimType,
  RimTypeOption,
} from '@/models/RimRenovation/RimRenovation'
import {
  countryBasedRenovationTypes,
  renovationTypes,
} from '@/resources/rimRenovation/renovationTypes'
import { countryBasedRimTypes, rimTypes } from '@/resources/rimRenovation/rimTypes'
import { CustomerInformation } from '@/models/Book/customerInformation'

interface RimRenovationState {
  renovationType: RimRenovationType
  images: string[]
  rimSize: number
  nrDamagedRims: number
  rimType: RimType | ''
  customerInformation: CustomerInformation
}

export const useRimRenovation = defineStore('rimRenovation', {
  state: () =>
    ({
      renovationType: 'helrenovering',
      images: [],
      rimSize: 0,
      nrDamagedRims: 0,
      rimType: '',
      customerInformation: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        socialSecurityNumber: '',
        place: '',
      },
    } as RimRenovationState),
  getters: {
    getPriceList(): PriceList {
      const settingStore = useSettings()
      return (priceList as countryBasedPriceList)[
        settingStore.twoLetterLanguage as keyof countryBasedPriceList
      ]
    },
    getRenovationTypes(): RimRenovationTypeOption[] {
      const settingStore = useSettings()
      return (renovationTypes as countryBasedRenovationTypes)[
        settingStore.twoLetterLanguage as keyof countryBasedRenovationTypes
      ]
    },
    getRimTypes(): RimTypeOption[] {
      const settingStore = useSettings()
      return (rimTypes as countryBasedRimTypes)[
        settingStore.twoLetterLanguage as keyof countryBasedRimTypes
      ]
    },
  },
  actions: {},
})
