import { Settings } from '@/models/Application/settings'
import { GRAPHQL, HTTP } from './http.client'
import { useSettings } from '@/store/settings.store'
import userMonitoring, { ErrorTypes } from '@/mixins/userMonitoring'
import { useConfigCat } from '@/store/config-cat.store'
import BookingSettingsQuery from '@/api/queries/BookingSettings.graphql'
import { ApolloError } from '@apollo/client'

export const getSettingsRequest = (culture: string): Promise<Settings> => {
  return useConfigCat()
    .getFeatureFlag('useGraphqlForBookingsettings')
    .then((flagStatus: Boolean) => {
      if (flagStatus) {
        // GRAPHQL IMPLEMENTATION
        const settingStore = useSettings()
        return GRAPHQL.query<any>({
          query: BookingSettingsQuery,
          variables: {
            siteId: settingStore.siteData.siteId,
          },
          errorPolicy: 'all',
        })
          .then((response) => {
            if (response.errors) {
              userMonitoring().trackErrorGraphql(
                'BookingSettingsQuery',
                JSON.stringify(response.errors)
              )
            }
            return response.data.bookingSettings as Settings
          })
          .catch((error: ApolloError) => {
            userMonitoring().trackErrorGraphql(
              'BookingSettingsQuery',
              error,
              JSON.stringify({ siteId: settingStore.siteData.siteId })
            )
            return {} as Settings
          })
      } else {
        // OLD IMPLEMENTATION
        return HTTP.get<Settings>(`BookingSettings/${useSettings().siteData.siteId}`)
          .then((response) => response.data)
          .catch((error) => {
            userMonitoring().trackError(
              `${ErrorTypes.api}: BookingSettings/${
                useSettings().siteData.siteId
              } failed with error: ${error}`
            )
            return {} as Settings
          })
      }
    })
}
