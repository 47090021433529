<template>
  <InputWrapper
    :id="id"
    :displayinline="displayinline"
    :hidelabel="hidelabel"
    :label="label"
    :labelinside="labelinside"
    :is-loading="isLoading"
    :is-valid="state.valid"
    :value-is-valid="valueIsValid"
  >
    <template #inputSlot>
      <input
        :id="id"
        v-maska
        v-focus="focusOnMount"
        class="input"
        :disabled="disabled"
        :data-maska="mask"
        :type="type"
        :value="state.message"
        :placeholder="placeholder"
        :checked="checked"
        :required="!!required"
        :autocomplete="autocomplete"
        :min="min"
        :max="max"
        :step="step"
        :name="name"
        :pattern="htmlPattern"
        @input="onInput($event)"
        @focus="onFocus(($event.target as HTMLInputElement).value)"
        @blur="onBlur(($event.target as HTMLInputElement).value)"
        @change="change(($event.target as HTMLInputElement).value)"
        @keyup.enter="onEnter"
      />
    </template>
    <template #afterInputSlot>
      <slot name="afterInputSlot" />
    </template>
    <template #labelSlot>
      <slot name="labelSlot">
        {{ label }}
      </slot>
    </template>
    <template #MessageSlot>
      <slot name="MessageSlot">
        <div v-if="notValidMessage || valueRequiredMessage" class="error">
          {{ state.errorMsg }}
        </div>
      </slot>
    </template>
  </InputWrapper>
</template>

<script lang="ts" setup>
import { EventBus } from '@/event-bus'
import { reactive, onMounted, ref, onUnmounted, watch, DirectiveBinding } from 'vue'
import InputWrapper from '@/components/generic/InputWrapper.vue'

const vFocus = {
  mounted(el: HTMLElement, binding: DirectiveBinding): void {
    if (binding.value) {
      el.focus()
    }
  },
}

const props = defineProps({
  mask: {
    type: String,
    required: false,
    default: '',
  },
  focusOnMount: {
    type: Boolean,
    required: false,
    default: false,
  },
  displayinline: {
    type: Boolean,
    required: false,
  },
  hidelabel: {
    type: Boolean,
    required: false,
  },
  label: {
    type: String,
    required: true,
  },
  labelinside: {
    type: Boolean,
    required: false,
  },
  isLoading: {
    type: Boolean,
    required: false,
    default: false,
  },
  type: {
    type: String,
    required: false,
    default: 'text',
  },
  placeholder: {
    type: String,
    required: false,
  },
  checked: {
    type: Boolean,
    required: false,
    default: undefined,
  },
  id: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: false,
  },
  required: {
    type: Boolean,
    required: false,
  },
  autocomplete: {
    type: String,
    required: false,
  },
  min: {
    type: Number,
    required: false,
  },
  max: {
    type: Number,
    required: false,
  },
  step: {
    type: Number,
    required: false,
  },
  pattern: {
    type: String,
    required: false,
  },
  inputValue: {
    type: String,
    required: false,
    default: '',
  },
  name: {
    type: String,
    required: false,
    default: '',
  },
  valueIsValid: {
    type: Boolean,
    required: false,
    default: undefined,
  },
  htmlPattern: {
    type: String,
    required: false,
    default: undefined,
  },
  skipBlurValidation: {
    type: Boolean,
    required: false,
    default: false,
  },
  useValidation: {
    type: Boolean,
    required: false,
    default: true,
  },
  notValidMessage: {
    type: String,
    required: false,
    default: '',
  },
  valueRequiredMessage: {
    type: String,
    required: false,
    default: '',
  },
})

const emit = defineEmits([
  'update:value',
  'update:valid',
  'input',
  'focus',
  'blur',
  'enter',
  'change',
  'onValid',
  'onInValid',
])

const input = ref()

const state = reactive({
  message: '',
  valid: false as boolean | undefined,
  followsPattern: false as boolean | undefined,
  presentInput: false as boolean | undefined,
  errorMsg: '',
})

state.valid = undefined
state.followsPattern = undefined
state.presentInput = undefined

function validatePattern(pattern: string, str: string): boolean {
  const regex = RegExp(pattern)
  return regex.test(str.trim())
}

function validateValue(value: string): void {
  state.message = value
  if (props.required) {
    if (value !== null || value !== undefined) {
      if (String(value).trim().length > 0) {
        state.presentInput = true
      } else {
        state.presentInput = false
        state.errorMsg = props.valueRequiredMessage
      }
    }
  }

  if (state.presentInput) {
    if (props.pattern == null || (validatePattern(props.pattern, value) && state.presentInput)) {
      state.followsPattern = true
    } else {
      state.followsPattern = false
      state.errorMsg = props.notValidMessage
    }
  }

  if (state.presentInput && state.followsPattern) {
    state.valid = true
    state.errorMsg = ''
    emit('onValid', value)
  } else if (!props.required) {
    state.valid = undefined
  } else {
    state.valid = false
  }

  emit('update:valid', state.valid)
}

function validate(): void {
  if (props.useValidation) {
    validateValue(state.message)
  }
}

onMounted(() => {
  if (props.inputValue) {
    state.message = props.inputValue
    validate()
  }
})
//Dont think this is needed because of the watch
//this.validateValue(this.message)

function setFocus(): void {
  if (input.value !== undefined) {
    input.value.focus()
  }
}

function setValue(value: string): void {
  state.message = value
}

function onInput(event: Event): void {
  state.message = (event.target as HTMLInputElement).value
  emit('update:value', state.message)
  emit('input', state.message)
  validate()
}

function onFocus(value: string): void {
  emit('focus', value)
}

function onBlur(value: string): void {
  state.message = value
  emit('blur', value)
  if (!props.skipBlurValidation) {
    validate()
  }
}

function onEnter(): void {
  emit('enter')
}

function change(value: string): void {
  state.message = value
  emit('change', value)
}

onMounted(() => {
  state.message = props.inputValue
})

onUnmounted(() => {
  EventBus.off('validateAll', () => {
    validate()
  })

  EventBus.off('validateById', (id: string | undefined) => {
    if (id == props.id) {
      validate()
    }
  })
})

EventBus.on('validateAll', () => {
  validate()
})

EventBus.on('validateById', (id: string | undefined) => {
  if (id == props.id) {
    validate()
  }
})

watch(
  () => props.inputValue,
  function (value) {
    validateValue(value)
  }
)
</script>

<style lang="scss" scoped>
@import './src/assets/scss/base';

.error {
  width: 100%;
  color: var(--color-bilia-active-red);
  font-size: var(--font-s);
  height: var(--font-m);
  line-height: var(--font-m);
  white-space: nowrap;
  text-overflow: ellipsis;
}

input {
  @include hellix-regular;
  padding: 0.65em 1em;
  border: solid 1px var(--input-border-color);
  border-radius: 2px;
  font-size: 1rem;
  width: 100%;
}

[type='search'] {
  appearance: none;
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    position: relative;
    right: 1rem;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: var(--color-bilia-mid-blue);
    @include icon-close-white();
    background-size: 0.5rem;
    background-position: 50% 50%;
  }
}

[type='text'],
[type='number'],
[type='tel'] {
  appearance: none;
  -moz-appearance: textfield;
}

input::placeholder {
  color: var(--input-text-color-placeholder);
}

.label-inside {
  :deep() input:placeholder-shown {
    + label {
      opacity: 0;
    }
  }
  :deep() input:not(:placeholder-shown) {
    padding: 1.1em 1em 0.2em 1em;
  }
  :deep() input:-webkit-autofill,
  :deep() input:-webkit-autofill:hover,
  :deep() input:-webkit-autofill:focus {
    + label {
      opacity: 1;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 3rem #fff inset;
  -webkit-box-shadow: 0 0 0px 3rem #fff inset;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pulse input {
  border-color: transparent;
  background: transparent;
}
</style>
