import { getOriginalServiceInfoRequest, getServicePlanRequest } from '@/api/vehicle.service'
import { ServiceInfo } from '@/models/Vehicle/ServiceInfo'
import { ServicePlan } from '@/models/Vehicle/ServicePlan'
import { VehicleRequest } from '@/models/Vehicle/VehicleRequest'
import { useRouter } from '@/router'
import { defineStore } from 'pinia'
import { useBooking } from './booking.store'
import { useSettings } from './settings.store'
import { useVehicle } from './vehicle.store'
import objectIsEmpty from '@/mixins/sharedFunctions/objectIsEmpty'
import { useText } from './text.store'
import { useConfigCat } from './config-cat.store'

import {
  Day,
  ServiceConfigurationInputType,
  ServiceGroup,
  WorkshopService,
} from '@/types/generated-types'
import { getServiceGroupsRequest, getServiceRequest } from '@/api/services.repository'
import ConfigInput from '@/types/extendedTypes/configInput'
import { operationTypeEnum } from '@/types/extendedTypes/operationType'
import { useMoreSale } from './moresale.store'

interface ServiceState {
  servicePlan: ServicePlan
  serviceInfo: ServiceInfo[]
  servicePlanIsLoading: boolean
  serviceConfigurationIsLoading: boolean
  serviceGroups: ServiceGroup[] | null
  errorCarmakeServiceNotMatch: boolean
}

export const useService = defineStore('service', {
  state: () =>
    ({
      servicePlan: {} as ServicePlan,
      serviceInfo: [] as ServiceInfo[],
      servicePlanIsLoading: false,
      serviceConfigurationIsLoading: false,
      errorCarmakeServiceNotMatch: false,
      serviceGroups: null,
    } as ServiceState),
  actions: {
    onRender() {
      const bookingStore = useBooking()
      bookingStore.clearSelectedBranch()
      bookingStore.clearSelectedTechnician()
    },
    async setup() {
      this.serviceGroups = null

      await this.fetchServiceGroups()
    },
    async complete(selectedService: WorkshopService): Promise<void> {
      const bookingStore = useBooking()
      const vehicleStore = useVehicle()
      const textStore = useText()
      const moreSaleStore = useMoreSale()

      if (
        this.hasServiceConfigInputType(
          selectedService as WorkshopService,
          ServiceConfigurationInputType.Mileage
        )
      ) {
        bookingStore.vehicle.typedMileage = vehicleStore.vehicle.typedMileage
      } else {
        bookingStore.vehicle.typedMileage = 0
        vehicleStore.vehicle.typedMileage = 0
      }

      bookingStore.options.leaveCarTime = null
      bookingStore.options.customerComment = ''
      bookingStore.SelectedDate = {} as Day
      this.servicePlan = {} as ServicePlan
      this.addSelectedWorkshopServiceToBooking(selectedService as WorkshopService)
      moreSaleStore.resetCurrentSelectedMoreSales()

      textStore.fetchUsp()
    },

    goToBranchStep() {
      const bookingStore = useBooking()
      const router = useRouter()
      const vehicleStore = useVehicle()
      router.push({
        name: 'branch',
        query: {
          regnr: bookingStore.vehicle.registrationNumber,
          serviceid: bookingStore.options.workshopServiceId,
          ...(vehicleStore.vehicle.typedMileage
            ? { mileage: vehicleStore.vehicle.typedMileage }
            : {}),
        },
      })
      document.body.classList.remove('modal-is-open')
    },

    async fetchSevicePlanAndConfiguration(): Promise<void> {
      this.fetchServicePlan().then(() => {
        if (objectIsEmpty(this.serviceInfo)) {
          this.fetchOriginalServiceInfo()
        }
      })
    },

    async fetchServicePlan(): Promise<void> {
      this.servicePlanIsLoading = true
      const settingsStore = useSettings()
      const vehilceStore = useVehicle()

      const vehicleRequest = new VehicleRequest(
        vehilceStore.vehicle.registrationNumber,
        settingsStore.culture,
        vehilceStore.vehicle.typedMileage,
        vehilceStore.vehicle.siteCarMake.value,
        vehilceStore.vehicle.model,
        settingsStore.siteData.siteId
      )
      await getServicePlanRequest(vehicleRequest).then((servicePlanResponse) => {
        this.servicePlan = servicePlanResponse
        this.serviceInfo = servicePlanResponse?.serviceTypes ?? {}
        this.servicePlanIsLoading = false
      })
    },

    async fetchOriginalServiceInfo(): Promise<void> {
      // AS THIS IS PART OF THE NEW GRAPHQL CALL FOR SERVICEPLAN THIS FUNCTION SHOULD BE REMOVED IN FAVOR OF THE NEW ONE WHEN THE GRAPHQL IMPLEMENTATION IS READY
      useConfigCat()
        .getFeatureFlag('useGraphqlForServiceinfo')
        .then(async (flagStatus: Boolean) => {
          if (!flagStatus) {
            this.serviceConfigurationIsLoading = true
            const vehilceStore = useVehicle()

            if (this.servicePlan.kitCode) {
              await getOriginalServiceInfoRequest(
                vehilceStore.vehicle.siteCarMake.value,
                this.servicePlan.kitCode
              ).then((serviceConfigurationResponse) => {
                this.serviceInfo = serviceConfigurationResponse
                this.serviceConfigurationIsLoading = false
              })
            } else {
              this.serviceConfigurationIsLoading = false
            }
          }
        })
    },

    resetState() {
      this.servicePlan = {} as ServicePlan
      this.serviceInfo = {} as ServiceInfo[]
    },

    async fetchServiceGroups(): Promise<void> {
      this.serviceGroups = await getServiceGroupsRequest()
    },

    // graphql
    async addSelectedWorkshopServiceToBooking(workshopService: WorkshopService): Promise<void> {
      const bookingStore = useBooking()
      bookingStore.workshopService = workshopService
      bookingStore.options.bookingType = operationTypeEnum[workshopService?.operationType] ?? ''
      bookingStore.options.bookingName = workshopService?.heading ?? ''
      bookingStore.options.workshopServiceId = workshopService?.id
      bookingStore.serviceConfigurationInputsResponse =
        (bookingStore.workshopService?.serviceConfigurationInputs as ConfigInput[]) ?? []
      bookingStore.clearBookedWheels()
    },

    async fetchWorkshopServicebyIds(serviceIds: string[]): Promise<void> {
      await getServiceRequest(serviceIds).then((workshopServcie) => {
        this.addSelectedWorkshopServiceToBooking(workshopServcie)
      })
    },

    async fetchWorkshopServicebyId(serviceId: string): Promise<WorkshopService> {
      return await getServiceRequest([serviceId])
    },

    hasServiceConfigInputs(workshopService: WorkshopService): boolean {
      return (
        (workshopService.serviceConfigurationInputs &&
          workshopService.serviceConfigurationInputs.length > 0) ??
        false
      )
    },
    hasServiceConfigInputType(
      workshopService: WorkshopService,
      inputType: ServiceConfigurationInputType
    ): boolean {
      const wheelSalesConfigInputIndex =
        workshopService.serviceConfigurationInputs?.findIndex(
          (serviceConfiguration) => serviceConfiguration.serviceConfigurationInputType == inputType
        ) ?? -1
      return wheelSalesConfigInputIndex != -1
    },
  },
})
