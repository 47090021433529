import { defineStore } from 'pinia'
import { AddMoreSaleQueryInput, MoreSaleInput } from '@/types/generated-types'
import { useBooking } from './booking.store'
import { useSettings } from './settings.store'
import { addMoreSalesToBookingRequest } from '@/api/booking.service'
import userMonitoring from '@/mixins/userMonitoring'
import ConfigInput, { MoreSaleExtended } from '@/types/extendedTypes/configInput'

interface MoreSaleState {
  moreSales: MoreSaleExtended[]
  currentSelectedMoreSales: MoreSaleExtended[]
  currentSelectedNumberOfErrors: number
}

export const useMoreSale = defineStore('moreSale', {
  state: () =>
    ({
      moreSales: [] as MoreSaleExtended[],
      currentSelectedMoreSales: [] as MoreSaleExtended[],
      currentSelectedNumberOfErrors: 0 as number,
    } as MoreSaleState),
  getters: {
    unbookedMoreSales(): MoreSaleExtended[] {
      const bookingStore = useBooking()
      return this.moreSales.filter(
        (moreSale) =>
          !bookingStore.selectedMoreSales.some(
            (bookedMoreSale) => bookedMoreSale.id === moreSale.id
          )
      )
    },
    isIncludedInCurrentMoreSales(): (moreSale: MoreSaleExtended) => boolean {
      return (moreSale: MoreSaleExtended) => {
        return this.currentSelectedMoreSales.some(
          (selectedMoreSale) => selectedMoreSale.id === moreSale.id
        )
      }
    },
    unselectedMoreSales(): MoreSaleExtended[] {
      return this.moreSales.filter(
        (moreSale) =>
          !this.currentSelectedMoreSales.some(
            (selectedMoreSale) => selectedMoreSale.id === moreSale.id
          )
      )
    },
  },
  actions: {
    setMoreSales(moreSales: MoreSaleExtended[]): void {
      this.currentSelectedMoreSales = []
      this.moreSales = moreSales
    },

    async addMoreSalesToBooking(selectedMoreSales: MoreSaleExtended[]): Promise<void> {
      const settingsStore = useSettings()
      const bookingStore = useBooking()
      const request = {
        bookingId: bookingStore.bookingId,
        moreSales: this.toMoreSaleInput(selectedMoreSales),
        regionCode: bookingStore.selectedBranch?.regionCode as number,
        siteId: settingsStore.siteData.siteId,
      } as AddMoreSaleQueryInput
      await addMoreSalesToBookingRequest(request)
        .then(() => {
          bookingStore.selectedMoreSales = [...bookingStore.selectedMoreSales].concat(
            selectedMoreSales
          )
        })
        .catch((error: Error) => {
          userMonitoring().trackError(
            `Add more sale to booking failed: ${error} request: ${JSON.stringify(request)}`
          )
        })
    },

    toMoreSaleInput(moreSales: MoreSaleExtended[]): MoreSaleInput[] {
      return moreSales.map((moreSale) => ({
        id: moreSale.id,
        name: moreSale.name,
        description: moreSale.description,
        eventName: moreSale.eventName,
        requireSocialSecurityNumber: moreSale.requireSocialSecurityNumber,
        socialSecurityNumber: moreSale.socialSecurityNumber,
        getArticleAndPriceFromEcommerce: moreSale.getArticleAndPriceFromEcommerce,
        showCommentField:
          moreSale.showCommentField || this.hasCustomerCommentServiceConfigurationInputs(moreSale), // Forcing showCommentField to true if we have customer comment inputs. This due to limitation in Backend.
        userComment: `${moreSale.userComment ?? ''} ${useBooking().concatenateUserComments(
          moreSale.serviceConfigurationInputs as ConfigInput[]
        )} `,
        price: {
          price: moreSale.price?.price ?? 0,
          biliaCardPrice: moreSale.price?.biliaCardPrice,
          textPrice: moreSale.price?.textPrice ?? '',
          priceDescription: moreSale.price?.priceDescription ?? '',
          isFromPrice: moreSale.price?.isFromPrice ?? false,
          disclaimer: moreSale.price?.disclaimer ?? '',
          packageCode: moreSale.price?.packageCode,
        },
      }))
    },

    setSelectedMoreSalesToCurrentSelected(): void {
      useBooking().selectedMoreSales = [...this.currentSelectedMoreSales]
    },

    isIncludedInSelectedMoreSales(moreSale: MoreSaleExtended): boolean {
      return moreSale
        ? this.currentSelectedMoreSales.some(
            (selectedMoreSale) => selectedMoreSale?.id === moreSale.id
          )
        : false
    },

    addToCurrentSelectedMoreSales(moreSale: MoreSaleExtended) {
      this.currentSelectedMoreSales = [...this.currentSelectedMoreSales, moreSale]
    },

    removeFromCurrentSelectedMoreSales(moreSale: MoreSaleExtended) {
      this.currentSelectedMoreSales.splice(this.currentSelectedMoreSales.indexOf(moreSale), 1)
    },
    resetCurrentSelectedMoreSales() {
      this.currentSelectedMoreSales = []
      this.currentSelectedNumberOfErrors = 0
      useBooking().selectedMoreSales = []
    },
    hasCustomerCommentServiceConfigurationInputs(moreSale: MoreSaleExtended): boolean {
      const customerCommentInputs = moreSale.serviceConfigurationInputs?.filter(
        (input) => input.serviceConfigurationInputType == 'CUSTOMER_COMMENT'
      )
      return (customerCommentInputs && customerCommentInputs.length > 0) ?? false
    },
    hasMoreSaleConfigurations(moreSale: MoreSaleExtended) {
      return moreSale.serviceConfigurationInputs && moreSale.serviceConfigurationInputs.length > 0
    },
    hasUserCommentError(moreSale: MoreSaleExtended): boolean {
      return (
        moreSale.showCommentField == true &&
        (moreSale.userComment == '' || moreSale.userComment == undefined)
      )
    },
    hasSocialSecurityNumberError(moreSale: MoreSaleExtended): boolean {
      return (
        (moreSale.requireSocialSecurityNumber && !moreSale.socialSecurityNumber) ||
        (!!moreSale.socialSecurityNumber &&
          !this.validateSocialSecurityNumber(moreSale.socialSecurityNumber))
      )
    },
    validateSocialSecurityNumber(str: string): boolean {
      return RegExp(
        '^(19|20)?\\d{2}(01|02|03|04|05|06|07|08|09|10|11|12)((0[1-9])|(1|2)[0-9]|(30|31))-\\d{4}$'
      ).test(str?.trim())
    },
    isCurrentSelectedValid(): boolean {
      return this.currentSelectedMoreSales.every(
        (moreSale) =>
          moreSale.isValid === true ||
          (!this.hasMoreSaleConfigurations(moreSale) &&
            (!this.hasUserCommentError(moreSale) || !this.hasSocialSecurityNumberError(moreSale)))
      )
    },
  },
})
