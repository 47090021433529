import { createRouter, createWebHistory } from 'vue-router'
import log from './middleware/log'
import short from 'short-uuid'
import objectIsEmpty from './mixins/sharedFunctions/objectIsEmpty'
import ResolveExternalUrlNavigation from './mixins/ResolveExternalUrlNavigation'
import { useBooking } from './store/booking.store'
import { useVehicle } from './store/vehicle.store'
import { useSettings } from './store/settings.store'
import { usePreliminaryBooking } from './store/preliminary-booking.store'
import { useService } from './store/service.store'
import { useConfigCat } from './store/config-cat.store'
import { nextTick } from 'process'
import userMonitoring from './mixins/userMonitoring'
import { useBranches } from './store/branch.store'
import { useCalendar } from './store/calendar.store'
import ConfigInput from './types/extendedTypes/configInput'
import { ServiceConfigurationInputType } from '@/types/generated-types'

const DefineVehicle = () => import('./views/VehicleDefine.vue')
const DefineService = () => import('./views/ServiceView.vue')
const DefineBranch = () => import('./views/BranchView.vue')
const Calendar = () => import('./views/CalendarView.vue')

const ContactInformation = () => import('./views/ContactInformation.vue')
const SelectedService = () => import('./views/SelectedService.vue')
const RimRenovation = () => import('./views/RimRenovation.vue')
const RimRenovationConfirm = () => import('./views/RimRenovationConfirm.vue')

const urlPrefix = '/v2'

const handleServiceIds = async (serviceIdArray: string | string[]): Promise<boolean> => {
  const serviceStore = useService()
  const bookingStore = useBooking()
  let serviceAdded = false
  if (!serviceIdArray) {
    return serviceAdded
  }

  // Convert serviceIdArray to array if string
  if (!Array.isArray(serviceIdArray)) {
    serviceIdArray = [serviceIdArray]
  }

  await serviceStore.fetchWorkshopServicebyIds(serviceIdArray).then(() => {
    if (!objectIsEmpty(bookingStore.workshopService)) {
      serviceAdded = true
    }
  })

  return serviceAdded
}

function formatUrl(url: string): string {
  if (url != null && url != '') {
    const mapObj = {
      '{#regnr}': useVehicle().vehicle.registrationNumber ?? '',
      '{#mileage}': useVehicle().vehicle.typedMileage ?? '',
    } as any

    const re = new RegExp(Object.keys(mapObj).join('|'), 'gi')
    url = url.replace(re, function (matched: string) {
      return mapObj[matched] as string
    })
  }

  return url
}

function getCorrelationId(
  toQuery: string | (string | null)[] | null,
  fromQuery: string | (string | null)[] | null
): void {
  const bookingStore = useBooking()
  if (bookingStore.correlationId) {
    return
  }

  if (toQuery) {
    bookingStore.correlationId = toQuery
  } else if (fromQuery) {
    bookingStore.correlationId = fromQuery
  } else {
    bookingStore.correlationId = short.uuid()
  }
}

function handleMileage(mileage: string): boolean {
  const vehicleStore = useVehicle()
  const bookingStore = useBooking()
  const configInputs = (bookingStore.workshopService.serviceConfigurationInputs ??
    []) as ConfigInput[]
  if (Number(mileage)) {
    vehicleStore.vehicle.typedMileage = Math.round(Number(mileage))

    /*
     * Validator of milage. To trigger validation on loading of route.
     */
    bookingStore.workshopService.serviceConfigurationInputs = configInputs.map((configInput) => {
      if (configInput.serviceConfigurationInputType === ServiceConfigurationInputType.Mileage) {
        return { ...configInput, isValid: true }
      } else return configInput
    })
    return true
  } else {
    return false
  }
}

function handleServiceConfig(): boolean {
  const bookingStore = useBooking()
  const configInputs = (bookingStore.workshopService.serviceConfigurationInputs ??
    []) as ConfigInput[]
  return configInputs.every((configInput) => {
    if (configInput.isValid == null || !configInput.isValid) {
      return false
    }

    return true
  })
}

const router = createRouter({
  history: createWebHistory(urlPrefix),
  routes: [
    {
      path: `/`,
      name: 'vehicle',
      component: DefineVehicle,

      meta: {
        middleware: [log],
        title: `${import.meta.env.VITE_VehicleDocumentTitle}`,
      },
      beforeEnter: async (to, from, next) => {
        const bookingStore = useBooking()
        const vehicleStore = useVehicle()
        const serviceStore = useService()

        if (to.query.uuid) {
          await bookingStore.fetchDriverByName(to.query.uuid as string).then(async () => {
            await vehicleStore
              .complete(bookingStore.campaignCustomer.registrationNumber)
              .then(async (result) => {
                if (result) {
                  bookingStore.customerInformation.firstName = bookingStore.campaignCustomer
                    .firstName
                    ? bookingStore.campaignCustomer.firstName
                    : ''
                  bookingStore.customerInformation.lastName = bookingStore.campaignCustomer.lastName
                    ? bookingStore.campaignCustomer.lastName
                    : ''
                  bookingStore.customerInformation.email = bookingStore.campaignCustomer.email
                    ? bookingStore.campaignCustomer.email
                    : ''

                  await serviceStore.setup()
                  next({ name: 'service', query: to.query })
                } else {
                  next()
                }
              })
          })
        } else if (to.query.regnr) {
          const registrationNumber: string = to.query.regnr as string
          await vehicleStore.complete(registrationNumber).then(async (result) => {
            if (result) {
              await serviceStore.setup()
              next({ name: 'service', query: to.query })
            } else {
              next()
            }
          })
        } else {
          next()
        }
      },
    },
    {
      path: `/${import.meta.env.VITE_RouteChooseServiceHeading}`,
      name: 'service',
      component: DefineService,
      meta: {
        middleware: [log],
        title: `${import.meta.env.VITE_ServiceDocumentTitle}`,
      },
      beforeEnter: async (to, from, next) => {
        const bookingStore = useBooking()
        const branchStore = useBranches()
        if (objectIsEmpty(bookingStore.vehicle)) {
          next({ name: 'vehicle', query: to.query })
        } else if (to.query.serviceid) {
          const serviceid: string | string[] = to.query.serviceid as string | string[]
          await handleServiceIds(serviceid).then(async (result) => {
            const serviceStore = useService()
            if (result) {
              handleMileage(to.query.mileage as string)
              if (!objectIsEmpty(bookingStore.workshopService)) {
                if (bookingStore.workshopService.externalUrl) {
                  ResolveExternalUrlNavigation(formatUrl(bookingStore.workshopService.externalUrl))
                } else if (
                  !serviceStore.hasServiceConfigInputs(bookingStore.workshopService) ||
                  handleServiceConfig()
                ) {
                  await serviceStore.complete(bookingStore.workshopService)
                  await branchStore.setup()
                  next({ name: 'branch', query: to.query })
                } else if (serviceStore.hasServiceConfigInputs(bookingStore.workshopService)) {
                  next({ name: 'selected-workshopservice', query: to.query })
                } else {
                  next()
                }
              } else {
                next()
              }
            } else {
              next()
            }
          })
        } else {
          next()
        }
      },
    },
    {
      path: `/service`,
      name: 'selected-workshopservice',
      meta: {
        middleware: [log],
        title: `${import.meta.env.VITE_MainServiceDocumentTitle}`,
      },
      component: SelectedService,
      beforeEnter: async (to, from, next) => {
        const bookingStore = useBooking()
        const vehicleStore = useVehicle()

        if (objectIsEmpty(bookingStore.vehicle)) {
          next({ name: 'vehicle', query: to.query })
        } else if (
          !vehicleStore.showWheelWearSales() &&
          vehicleStore.hasWheelSalesServiceConfigInput()
        ) {
          next({ name: 'branch', query: to.query })
        } else {
          next()
        }
      },
    },
    {
      path: `/${import.meta.env.VITE_RouteFindSlotHeading}`,
      name: 'branch',
      component: DefineBranch,

      meta: {
        middleware: [log],
        title: `${import.meta.env.VITE_BranchDocumentTitle}`,
      },
      beforeEnter: async (to, from, next) => {
        const bookingStore = useBooking()
        const branchStore = useBranches()
        const calendarStore = useCalendar()
        calendarStore.$reset()

        if (objectIsEmpty(bookingStore.vehicle)) {
          next({ name: 'vehicle', query: to.query })
        } else if (to.query.branch) {
          const res = await branchStore.fetchBranchById(to.query.branch as string)
          if (res) {
            await branchStore.complete(undefined, bookingStore.selectedBranch, undefined)
            next({ name: 'calendar', query: to.query })
          } else {
            next()
          }
        } else {
          next()
        }
      },
    },
    {
      path: `/${import.meta.env.VITE_RouteCalendar}`,
      name: 'calendar',
      component: Calendar,

      meta: {
        middleware: [log],
        title: `${import.meta.env.VITE_CalendarDocumentTitle}`,
      },
      beforeEnter: async (to, from, next) => {
        const bookingStore = useBooking()
        const calendarStore = useCalendar()
        const preliminaryBookingStore = usePreliminaryBooking()
        if (objectIsEmpty(bookingStore.vehicle)) {
          next({ name: 'vehicle', query: to.query })
        } else if (
          preliminaryBookingStore.isValid &&
          preliminaryBookingStore.preliminaryBooking.PreliminaryBookingId &&
          to.query.container
        ) {
          if (bookingStore.workshopService.isSlot) {
            bookingStore.SelectedDate.date = preliminaryBookingStore.preliminaryBooking.SelectedDate
            bookingStore.selectedContainer = preliminaryBookingStore.preliminaryBooking.Container
          } else {
            await calendarStore.fetchBranchSchedule()
            const foundContainer = await calendarStore.technicians.find((container) => {
              return container.containerId == (to.query.container as unknown as number)
            })
            if (foundContainer) {
              bookingStore.selectedContainer = {
                containerId: foundContainer.containerId,
                technician: foundContainer,
              }
            }
          }
          next({ name: 'reservation', query: to.query })
        } else {
          await calendarStore.setup(false)
          next()
        }
      },
    },
    {
      path: `/${import.meta.env.VITE_RouteReservation}`,
      name: 'reservation',
      component: ContactInformation,
      meta: {
        middleware: [log],
        title: `${import.meta.env.VITE_CalendarDocumentTitle}`,
      },
      beforeEnter: (to, from, next) => {
        const preliminaryBookingStore = usePreliminaryBooking()
        const bookingStore = useBooking()
        if (objectIsEmpty(bookingStore.vehicle)) {
          next({ name: 'vehicle', query: to.query })
        } else if (objectIsEmpty(bookingStore.workshopService)) {
          next({ name: 'service', query: to.query })
        } else if (objectIsEmpty(bookingStore.selectedBranch)) {
          next({ name: 'branch', query: to.query })
        } else if (!preliminaryBookingStore.isValid) {
          router.go(0)
        } else {
          next()
        }
      },
    },
    {
      path: `/${import.meta.env.VITE_RouteConfirmation}`,
      name: 'confirmation',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('./views/confirmation.vue'),
      meta: {
        middleware: [log],
        title: `${import.meta.env.VITE_ConfirmationDocumentTitle}`,
      },
      beforeEnter: (to, from, next) => {
        const bookingStore = useBooking()
        if (objectIsEmpty({ ...bookingStore.vehicle })) {
          next({ name: 'vehicle' })
        } else {
          next()
        }
      },
    },
    {
      path: `/${import.meta.env.VITE_RouteRimRenovation}`,
      name: 'rim-renovation',
      meta: {
        middleware: [log],
        title: `${import.meta.env.VITE_RimRenovationDocumentTitle}`,
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      beforeEnter: async (to, from, next) => {
        const vehicleStore = useVehicle()
        let status = false

        try {
          status = (await useConfigCat().getFeatureFlag('rimRenovation')) ?? false
        } catch (e) {
          console.log(e)
        }

        if (!status) {
          return { name: 'vehicle' }
        }

        userMonitoring().trackAction('RIM RENOVATION - Rim renovation view')

        if (to.query.regnr) {
          const registrationNumber: string = to.query.regnr as string
          await vehicleStore.complete(registrationNumber).then(async () => {
            next()
          })
        } else {
          next()
        }
      },
      component: RimRenovation,
    },
    {
      path: `/${import.meta.env.VITE_RouteRimRenovationConfirm}`,
      name: 'rim-renovation-confirmation',
      component: RimRenovationConfirm,
      meta: {
        middleware: [log],
        title: `${import.meta.env.VITE_RimRenovationDocumentTitle}`,
      },
    },
    {
      path: '/health/live',
      name: 'live',
      component: {},
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('./views/error.vue'),
    },
    {
      path: '/:catchAll(.*)',
      name: '404',
      component: () => import('./views/error.vue'),
      beforeEnter: (to, from, next) => {
        next()
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return { left: 0, top: 0 }
  },
})

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory([context, middleware, index]: any) {
  const subsequentMiddleware = middleware[index]

  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next

  return (...parameters: any) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters)
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory([context, middleware, index + 1])
    subsequentMiddleware({ ...context, next: nextMiddleware })
  }
}

router.beforeEach((to, from, next) => {
  const settingsStore = useSettings()
  settingsStore.evaluatingRoutes = true

  getCorrelationId(to.query.correlationId, from.query.correlationId)
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]

    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory([context, middleware, 1])

    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

router.afterEach((to) => {
  const settingsStore = useSettings()
  settingsStore.evaluatingRoutes = false

  nextTick(() => {
    // DONT KNOW WHY THE TITLE IS TRANSFORMED INTO A STRING?
    if (to.meta.title != 'undefined') {
      document.title = to.meta.title as string
    } else {
      document.title = `${import.meta.env.VITE_Title}`
    }
  })
})

export function useRouter() {
  return router
}
